export const MenuItems = [
    {
      title: 'ABOUT',
      path: '/about',
      className: 'dropdown-link'
    },
    {
      title: 'PRODUCTION',
      path: '/info',
      className: 'dropdown-link'
    },
    {
      title: 'MEDIA',
      path: '/media',
      className: 'dropdown-link'
    },
    {
      title: 'SCREENINGS',
      path: '/screening',
      className: 'dropdown-link'
    },
    {
      title: 'THE BOOK',
      path: '/book',
      className: 'dropdown-link'
    },
  ];